<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">

      <div class="content">
        <Header
          :meta="meta"
          :page-title="moduleConfig.page_title || '集點卡'"
          :page-image="moduleConfig.page_image"
          :page-kv-image="moduleConfig.page_kv_image"
          :header-section="moduleConfig.header_section"
        />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import { mapGetters } from "vuex";
import themeColor from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColor],
  components: {
    Header,
  },
  computed: {
    ...mapGetters("liffGeneral", ["themeConfig", "meta"]),
    ...mapGetters("liffModule", ["getModule"]),
    moduleConfig() {
      return this.getModule("liff_rewards_card")?.module_config || {};
    },
  },
  data() {
    return {
      themeConfigPage: "rewards",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/css/liff/main.css";
@import "../../../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
}

.content {
  padding-bottom: 24px;
  background: var(--liff-content_bg_color);
}

::v-deep .footer{
  max-width: 768px;
  margin: auto;
}
</style>
